<template>
  <div class="h5-consult">
    <p>
      <van-icon name="info" />
      <span>如果您在国产化替代升级过程中有任何问题，请提交咨询单，鸿联联创的技术专家会给您最专业的解答</span>
      <!-- <span>咨询单提交后，平台将在1个工作日与您电话联系，请保持手机畅通</span> -->
    </p>
    <div>
      <van-form validate-first @submit="onFailed">
        <div class="h5-consult-head">
          <van-field
            label="咨询主题"
            v-model="form.consultTitle"
            name="咨询主题"
            placeholder="请填写咨询主题"
            :rules="[{ required: true, message: '请填写咨询主题' }]"
          />
          <van-field
            v-model="form.consultDesc"
            type="textarea"
            name="咨询内容"
            placeholder="请输入咨询内容"
            :rules="[{ required: true, message: '请填写请输入咨询内容' }]"
          />
          <div class="h5-consult-uploader">
            <van-uploader
              :after-read="afterRead"
              :max-count="1"
              preview-size="60px"
              accept="image/*,.doc,.docx,.pdf,.sls"
              v-model="fileList"
            />
            <!-- <p>支持JPG,PNG,DOC,XLS,PDF文件</p> -->
          </div>
        </div>
        <div>
          <van-field
            label="联系人"
            v-model="form.contactName"
            name="联系人"
            placeholder="请填写联系人"
            :rules="[{ required: true, message: '请填写联系人' }]"
          />
          <van-field
            label="手机号"
            v-model="form.contactPhone"
            name="手机号"
            :maxlength="11"
            placeholder="请填写手机号"
            :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <van-field
            label="验证码"
            v-model="form.contactPhoneCode"
            name="验证码"
            placeholder="请填写验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #button
              ><span @click="sendMobileCode">{{
                phoneCodeBtn ? `重新发送(${reciprocalNum})` : "发送验证码"
              }}</span></template
            >
          </van-field>
        </div>
        <div style="margin: 16px">
          <van-button round block class="btn-red" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getConsultCreate,uploadConsult } from "@/api/news";
import { sendMobile } from "@/api/common";
export default {
  data() {
    return {
      form: {
        consultTitle: "",
        consultDesc: "",
        contactName: "",
        contactPhone: "",
        contactPhoneCode: "",
        annex: "",
      },
      reciprocalNum: 60,
      phoneCodeBtn: false,
      fileList:[],
    };
  },
  methods: {
    onFailed(values) {
      let { form } = this;
      let _this = this
      let data = {
        consultTitle: form.consultTitle,
        consultContent: form.consultDesc,
        contactDetail: form.contactPhone,
        contactPerson: form.contactName,
        consultAttmId: form.annex,
        contactCompany: "",
      };
      getConsultCreate(data).then((res) => {
        // this.$notify({ type: "success", message: "提交成功！" });
        this.$dialog.alert({
          title: '提交成功',
          message: '咨询单提交后，平台将在1个工作日与您电话联系，请保持手机畅通',
          confirmButtonColor:'#EA0B06',
          confirmButtonText:"我知道了"
        }).then(() => {
          _this.form = {
            consultTitle: "",
            consultDesc: "",
            contactName: "",
            contactPhone: "",
            contactPhoneCode: "",
            annex: "",
          }
        });
      });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      uploadConsult(formData,'9999').then((res) => {
        // this.$emit("upload-success", res.data)
      });
    },
    sendMobileCode() {
      const { contactPhone, phoneCodeBtn } = this.form;
      if (this.phoneCodeBtn) {
        return;
      }
      if (!contactPhone || contactPhone.length < 11) {
        this.$notify({ type: "warning", message: `手机号输入有误` });
        return;
      }
      sendMobile(contactPhone).then((code) => {
        this.$notify({ type: "success", message: `发送成功` });
        this.phoneCodeBtn = true
        this.reciprocalItme()
      });
    },
    reciprocalItme() {
      let _this = this;
      let num = this.reciprocalNum;
      let itme = null;
      itme = setInterval(function () {
        num--;
        if (num <= 0) {
          _this.phoneCodeBtn = false;
          _this.reciprocalNum = 60;
          clearInterval(itme);
          return;
        }
        _this.reciprocalNum = num;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-consult {
  padding: 12px 0;
  > p {
    margin: 0 16px 12px;
    background: #fff5e6;
    border-radius: 4px;
    border: 1px solid #ffe1b4;
    padding: 12px;
    display: flex;
    > i {
      color: #fe9400;
      line-height: 17px;
      margin-right: 8px;
      font-size: 16px;
    }
    > span {
      font-size: 12px;
      color: #999;
      line-height: 17px;
      flex: 1;
      text-align: justify;
    }
  }
  > div {
    padding-bottom: 12px;
    .h5-consult-head {
      border-bottom: 8px solid #f5f5f5;
    }
    .h5-consult-uploader {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      > p {
        font-size: 14px;
        color: #999;
      }
    }
  }
}
</style>
<style>
.h5-consult-uploader .van-uploader__upload {
  margin-bottom: 0;
}
</style>
