import { PortalRequest as request } from "@/utils/request";

// 新增用户咨询
export const getConsultCreate = (data) => {
    return request({
        url: "/consult/create",
        method: "post",
        data,
    });
};

// 新增用户咨询
export const uploadConsult = (data,type) => {
    return request({
        url: `/file/upload/${type}`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data
    });
};